<template>
  <ais-instant-search
    :index-name="indexName"
    :search-client="searchClient"
    :routing="routing"
  >
    <!-- SRP page  -->
    <div class="searchPage container-fluid pb-4" v-if="!detailsPage.viewing">
      <div class="container page-container">
        <ais-configure :hitsPerPage="12" />

        <div class="row pb-2">
          <div id="autoHubBanner"></div>
        </div>

        <div
          class="row pt-2 pb-0 pb-sm-2 justify-content-between"
          id="filterSection"
        >
          <ais-menu attribute="new" class="newFilterMenu">
            <ul
              slot-scope="{ refine, createURL }"
              class="condition-new-filters"
            >
              <li>
                <a
                  :href="createURL('true')"
                  :style="{
                    fontWeight: conditionNew ? 'bold' : '',
                    background: conditionNew ? '#0072CE' : '',
                    color: conditionNew ? '#FFFFFF' : '#0072CE',
                    pointerEvents: conditionNew ? 'none' : 'all',
                  }"
                  @click.prevent="refineCondition(refine, 'true')"
                >
                  <span>New</span>
                </a>
              </li>

              <li>
                <a
                  :href="createURL('false')"
                  :style="{
                    fontWeight: !conditionNew ? 'bold' : '',
                    background: !conditionNew ? '#0072CE' : '',
                    color: !conditionNew ? '#FFFFFF' : '#0072CE',
                    pointerEvents: !conditionNew ? 'none' : 'all',
                  }"
                  @click.prevent="refineCondition(refine, 'false')"
                >
                  <span>Used</span>
                </a>
              </li>
            </ul>
          </ais-menu>

          <ais-sort-by
            attribute="sortBy"
            class="clawson-sort-by"
            :items="[
              { value: indexName, label: 'Sort by' },
              { value: indexName + '_price_asc', label: 'Price (Lowest)' },
              { value: indexName + '_price_desc', label: 'Price (Highest)' },
              { value: indexName + '_year_desc', label: 'Year (Newest)' },
              { value: indexName + '_year_asc', label: 'Year (Oldest)' },
              { value: indexName + '_mileage_asc', label: 'Mileage (Lowest)' },
              {
                value: indexName + '_mileage_desc',
                label: 'Mileage (Highest)',
              },
            ]"
          />

          <ais-search-box
            placeholder="Search all results"
            class="mt-3 mb-2 mb-sm-0 my-md-0"
          />
        </div>

        <!-- Filters Dropdowns-->
        <div class="row pt-2 filterSection">
          <!-- Certification Filter -->
          <div class="d-none d-lg-block dropdown filterDropdown">
            <button
              class="filterBtn"
              id="certificationFilterTrigger"
              type="button"
              data-toggle="dropdown"
              @click="scrollToFilter()"
            >
              Certification
            </button>
            <div
              class="dropdown-menu"
              aria-labelledby="certificationFilterTrigger"
            >
              <ais-panel class="mb-4">
                <template v-slot:header>
                  <h6>Certification</h6>
                </template>
                <template v-slot:default>
                  <ais-refinement-list
                    attribute="certification"
                    :transform-itmes="transformCertification"
                  >
                    <template v-slot:default="{ items, refine, createURL }">
                      <ul class="ais-RefinementList-list certified-filter-list">
                        <li
                          v-for="item in items"
                          :key="item.value"
                          class="ais-RefinementList-item"
                          :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                        >
                          <a
                            class="ais-RefinementList-Checkbox"
                            :href="createURL(item.value)"
                            :style="{ fontWeight: item.isRefined ? 'bold' : '' }"
                            @click.prevent="refine(item.value)"
                          >
                            <span
                              v-if="item.label == 'Certified Pre-Owned'"
                              class="ais-RefinementList-label"
                              >Certified Pre-Owned</span
                            >
                            <span
                              v-else-if="
                                item.label == 'Clawson Certified Pre-Owned'
                              "
                              class="ais-RefinementList-label"
                              >Clawson Certified Pre-Owned</span
                            >
                            <span
                              v-else-if="item.label == 'Clawson Customs'"
                              class="ais-RefinementList-label"
                              >Clawson Customs</span
                            >
                            <span
                              v-else-if="item.label == 'HondaTrue Used'"
                              class="ais-RefinementList-label"
                              >HondaTrue Used</span
                            >
                            <span
                              v-else-if="item.label == 'HondaTrue Certified'"
                              class="ais-RefinementList-label"
                              >HondaTrue Certified</span
                            >
                            <span
                              v-else-if="item.label == 'HondaTrue Certified+'"
                              class="ais-RefinementList-label"
                              >HondaTrue Certified+</span
                            >
                            <span class="ais-RefinementList-count">{{ item.count }}</span>
                          </a>
                        </li>
                      </ul>
                    </template>
                  </ais-refinement-list>
                </template>
                <template v-slot:footer>
                  <button
                    v-if="isMobile"
                    @click="closeDropdown"
                    class="btn btn-primary btn-block mt-4"
                  >
                    Apply Filters
                  </button>
                </template>
              </ais-panel>
            </div>
          </div>

          <!-- Make & Model Filter -->
          <div class="d-none d-lg-block dropdown filterDropdown">
            <button
              class="filterBtn"
              id="makeModelFilterTrigger"
              type="button"
              data-toggle="dropdown"
              @click="scrollToFilter()"
            >
              <span class="d-none d-lg-inline">Make, Model & Trim</span>
              <span class="d-none d-md-inline d-lg-none">Make/Model/Trim</span>
              <span class="d-inline d-md-none">Make</span>
            </button>

            <div
              class="dropdown-menu lg-menu"
              id="makeModelMenu"
              aria-labelledby="makeModelFilterTrigger"
            >
              <div class="d-flex flex-column">
                <ais-panel class="mb-4">
                  <template v-slot:header>
                    <h6>Make</h6>
                  </template>
                  <template v-slot:default>
                    <ais-refinement-list attribute="make" :limit="100">
                      <ul
                        slot-scope="{ items, refine, createURL }"
                        class="ais-RefinementList-list"
                      >
                        <li
                          v-for="item in items"
                          :key="item.value"
                          class="ais-RefinementList-item"
                          :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                        >
                          <a
                            class="ais-RefinementList-checkbox"
                            :href="createURL( item.value )"
                            :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                            @click.prevent="refine( item.value )"
                          >
                            <span class="ais-RefinementList-label">{{ item.label }}</span>
                            <span class="ais-RefinementList-count">{{ item.count }}</span>
                          </a>
                        </li>
                      </ul>
                    </ais-refinement-list>
                  </template>
                </ais-panel>

                <ais-panel class="mb-4">
                  <template v-slot:header>
                    <h6>Model</h6>
                  </template>
                  <template v-slot:default>
                    <ais-refinement-list attribute="model" :limit="40">
                      <ul
                        slot-scope="{ items, refine, createURL }"
                        class="ais-RefinementList-list"
                      >
                        <li
                          v-for="item in items"
                          :key="item.value"
                          class="ais-RefinementList-item"
                          :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                        >
                          <a
                            class="ais-RefinementList-checkbox"
                            :href="createURL( item.value )"
                            :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                            @click.prevent="refine( item.value )"
                          >
                            <span class="ais-RefinementList-label">{{ item.label }}</span>
                            <span class="ais-RefinementList-count">{{ item.count }}</span>
                          </a>
                        </li>
                      </ul>
                    </ais-refinement-list>
                  </template>
                </ais-panel>

                <ais-panel>
                  <template v-slot:header>
                    <h6>Trim</h6>
                  </template>
                  <template v-slot:default>
                    <ais-refinement-list attribute="trim">
                      <ul
                        slot-scope="{ items, refine, createURL }"
                        class="ais-RefinementList-list"
                      >
                        <li
                          v-for="item in items"
                          :key="item.value"
                          class="ais-RefinementList-item"
                          :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                        >
                          <a
                            class="ais-RefinementList-checkbox"
                            :href="createURL( item.value )"
                            :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                            @click.prevent="refine( item.value )"
                          >
                            <span class="ais-RefinementList-label">{{ item.label }}</span>
                            <span class="ais-RefinementList-count">{{ item.count }}</span>
                          </a>
                        </li>
                      </ul>
                    </ais-refinement-list>
                  </template>
                </ais-panel>
              </div>

              <button
                v-if="isMobile"
                @click="closeDropdown"
                class="applyMakeModelButton mt-4 btn btn-block btn-primary"
              >
                Apply Filters
              </button>
            </div>
          </div>

          <!-- Price Filter -->
          <div class="d-none d-lg-block dropdown filterDropdown">
            <button
              class="filterBtn"
              id="priceFilterTrigger"
              type="button"
              data-toggle="dropdown"
              @click="scrollToFilter()"
            >
              Price
            </button>
            <div class="dropdown-menu" aria-labelledby="priceFilterTrigger">
              <ais-panel>
                <div class="rangeInputWrapper">
                  <h6>Price</h6>
                  <!-- range input -> dropdown -->
                  <ais-range-input attribute="price">
                    <div slot-scope="{ currentRefinement, range, refine }">
                      <select
                        name="priceMin"
                        @change="
                          refine({
                            min: $event.currentTarget.value,
                            max: formatMaxValue(
                              currentRefinement.max,
                              range.max
                            ),
                          })
                        "
                      >
                        <option disabled selected>Min</option>
                        <option value="0">No Min</option>

                        <option
                          :selected="currentRefinement.min == price.value"
                          v-for="price in priceRange"
                          :value="price.value"
                          :key="price.value"
                        >
                          {{ price.text }}
                        </option>
                      </select>

                      <span>-</span>

                      <select
                        name="priceMax"
                        @change="
                          refine({
                            min: formatMinValue(
                              currentRefinement.min,
                              range.min
                            ),
                            max: $event.currentTarget.value,
                          })
                        "
                      >
                        <option disabled selected>Max</option>
                        <option value="90000">No Max</option>
                        <option
                          :selected="currentRefinement.max == price.value"
                          v-for="price in priceRange"
                          :value="price.value"
                          :key="price.value"
                        >
                          {{ price.text }}
                        </option>
                      </select>
                    </div>
                  </ais-range-input>

                  <!-- <ais-range-input attribute="price">
                    <span slot="separator">-</span>
                  </ais-range-input> -->
                </div>
                <ais-range-input attribute="price">
                  <div slot-scope="{ currentRefinement, range, refine }">
                    <vue-slider
                      :tooltip="'none'"
                      :min="range.min"
                      :max="range.max"
                      :lazy="false"
                      :contained="true"
                      :value="toValue(currentRefinement, range)"
                      :marks="[range.min, range.max]"
                      :railStyle="{ backgroundColor: '#dfdfdf' }"
                      :processStyle="{ backgroundColor: '#0072ce' }"
                      :labelStyle="{
                        marginTop: '1.25em',
                        color: '#939393',
                        fontSize: '.9rem',
                      }"
                      ref="slider2"
                      :enableCross="false"
                      @drag-start="updateSlider2()"
                      @change="refine({ min: $event[0], max: $event[1] })"
                    >
                      <template v-slot:dot="{ focus }">
                        <div :class="['custom-dot', { focus }]"></div>
                      </template>
                    </vue-slider>
                  </div>
                </ais-range-input>
              </ais-panel>
              <button
                v-if="isMobile"
                @click="closeDropdown"
                class="mt-5 btn btn-block btn-primary"
              >
                Apply Filters
              </button>
            </div>
          </div>

          <!-- Year & Mileage Filter -->
          <div class="dropdown filterDropdown d-none d-lg-block">
            <button
              class="filterBtn"
              id="yearFilterTrigger"
              type="button"
              data-toggle="dropdown"
              @click="scrollToFilter()"
            >
              Year & Mileage
            </button>
            <div class="dropdown-menu" aria-labelledby="yearFilterTrigger">
              <div class="row">
                <ais-panel class="col-12">
                  <div class="rangeInputWrapper">
                    <h6>Year</h6>

                    <ais-range-input attribute="year">
                      <div slot-scope="{ currentRefinement, range, refine }">
                        <select
                          name="yearMin"
                          @change="
                            refine({
                              min: $event.currentTarget.value,
                              max: formatMaxValue(
                                currentRefinement.max,
                                range.max
                              ),
                            })
                          "
                        >
                          <option disabled selected>Min</option>
                          <option
                            :selected="currentRefinement.min == year"
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>

                        <span>-</span>

                        <select
                          name="yearMax"
                          @change="
                            refine({
                              min: formatMinValue(
                                currentRefinement.min,
                                range.min
                              ),
                              max: $event.currentTarget.value,
                            })
                          "
                        >
                          <option disabled selected>Max</option>
                          <option
                            :selected="currentRefinement.max == year"
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </ais-range-input>

                    <!-- <ais-range-input attribute="year">
                      <span slot="separator">-</span>
                    </ais-range-input> -->
                  </div>
                  <ais-range-input attribute="year" class="mb-6">
                    <div slot-scope="{ currentRefinement, range, refine }">
                      <vue-slider
                        :tooltip="'none'"
                        :min="range.min"
                        :max="range.max"
                        :lazy="false"
                        :contained="true"
                        :value="toValue(currentRefinement, range)"
                        :marks="[range.min, range.max]"
                        :railStyle="{ backgroundColor: '#dfdfdf' }"
                        :processStyle="{ backgroundColor: '#0072ce' }"
                        :absorb="true"
                        :labelStyle="{
                          marginTop: '1.25em',
                          color: '#939393',
                          fontSize: '.9rem',
                        }"
                        ref="slider1"
                        :enableCross="false"
                        @drag-start="updateSlider1()"
                        @change="refine({ min: $event[0], max: $event[1] })"
                      >
                        <template v-slot:dot="{ focus }">
                          <div :class="['custom-dot', { focus }]"></div>
                        </template>
                      </vue-slider>
                    </div>
                  </ais-range-input>
                </ais-panel>
                <ais-panel class="col">
                  <div class="rangeInputWrapper">
                    <h6>Mileage</h6>

                    <ais-range-input attribute="mileage">
                      <div slot-scope="{ currentRefinement, range, refine }">
                        <select
                          name="mileageMin"
                          @change="
                            refine({
                              min: $event.currentTarget.value,
                              max: formatMaxValue(
                                currentRefinement.max,
                                range.max
                              ),
                            })
                          "
                        >
                          <option disabled selected>Min</option>
                          <option value="0">No Min</option>
                          <option value="0">0</option>
                          <option
                            :selected="currentRefinement.min == miles.value"
                            v-for="miles in mileageRange"
                            :key="miles.value"
                            :value="miles.value"
                          >
                            {{ miles.value }}
                          </option>
                        </select>

                        <span>-</span>

                        <select
                          name="mileageMax"
                          @change="
                            refine({
                              min: formatMinValue(
                                currentRefinement.min,
                                range.min
                              ),
                              max: $event.currentTarget.value,
                            })
                          "
                        >
                          <option disabled selected>Max</option>
                          <option value="0">0</option>
                          <option
                            :selected="currentRefinement.max == miles.value"
                            v-for="miles in mileageRange"
                            :key="miles.value"
                            :value="miles.value"
                          >
                            {{ miles.value }}
                          </option>
                        </select>
                      </div>
                    </ais-range-input>

                    <!-- <ais-range-input attribute="mileage">
                      <span slot="separator">-</span>
                    </ais-range-input> -->
                  </div>
                  <ais-range-input attribute="mileage">
                    <div slot-scope="{ currentRefinement, range, refine }">
                      <vue-slider
                        :tooltip="'none'"
                        :min="range.min"
                        :max="range.max"
                        :lazy="false"
                        :contained="true"
                        :value="toValue(currentRefinement, range)"
                        :marks="[range.min, range.max]"
                        :railStyle="{ backgroundColor: '#dfdfdf' }"
                        :processStyle="{ backgroundColor: '#0072ce' }"
                        :absorb="true"
                        :labelStyle="{
                          marginTop: '1.25em',
                          color: '#939393',
                          fontSize: '.9rem',
                        }"
                        ref="slider5"
                        :enableCross="false"
                        @drag-start="updateSlider5()"
                        @change="refine({ min: $event[0], max: $event[1] })"
                      >
                        <template v-slot:dot="{ focus }">
                          <div :class="['custom-dot', { focus }]"></div>
                        </template>
                      </vue-slider>
                    </div>
                  </ais-range-input>
                </ais-panel>
                <button
                  v-if="isMobile"
                  @click="closeDropdown"
                  class="applyBodyButton mt-4 btn btn-block btn-primary"
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </div>

          <!-- Body type Filter -->
          <div class="d-none d-lg-block dropdown filterDropdown">
            <button
              class="filterBtn"
              id="bodyTypeFilterTrigger"
              type="button"
              data-toggle="dropdown"
              @click="scrollToFilter()"
            >
              <span class="d-none d-lg-inline">Body Type</span>
              <span class="d-inline d-lg-none">Body</span>
            </button>
            <div
              class="dropdown-menu md-menu"
              aria-labelledby="bodyTypeFilterTrigger"
            >
              <ais-panel>
                <template v-slot:header>
                  <h6>Body Type</h6>
                </template>
                <template v-slot:default>
                  <ais-refinement-list attribute="body">
                    <ul
                      slot-scope="{ items, refine, createURL }"
                      class="ais-RefinementList-list"
                    >
                      <li
                        v-for="item in items"
                        :key="item.value"
                        class="ais-RefinementList-item"
                        :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                      >
                        <a
                          class="ais-RefinementList-checkbox"
                          :href="createURL( item.value )"
                          :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                          @click.prevent="refine( item.value )"
                        >
                          <span class="ais-RefinementList-label">{{ item.label }}</span>
                          <span class="ais-RefinementList-count">{{ item.count }}</span>
                        </a>
                      </li>
                    </ul>
                  </ais-refinement-list>
                </template>
              </ais-panel>
              <button
                v-if="isMobile"
                @click="closeDropdown"
                class="applyBodyButton mt-4 btn btn-block btn-primary"
              >
                Apply Filters
              </button>
            </div>
          </div>

          <!-- Show More Button -->
          <div class="dropdown filterDropdown d-none d-lg-block showMore">
            <button
              class="filterBtn"
              id="showMoreTrigger"
              type="button"
              data-toggle="dropdown"
              data-flip="false"
              @click="scrollToFilter()"
            >
              <span class="d-none d-lg-inline">Show More</span>
              <span class="d-inline d-lg-none">More</span>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right xl-menu"
              aria-labelledby="showMoreTrigger"
            >
              <div class="row">
                <!-- Year Filter -->
                <div class="col-6 d-block d-lg-none">
                  <ais-panel>
                    <div class="rangeInputWrapper">
                      <h6>Year</h6>
                      <ais-range-input attribute="year">
                        <span slot="separator">-</span>
                      </ais-range-input>
                    </div>
                    <ais-range-input attribute="year">
                      <div slot-scope="{ currentRefinement, range, refine }">
                        <vue-slider
                          :tooltip="'none'"
                          :min="range.min"
                          :max="range.max"
                          :lazy="false"
                          :contained="true"
                          :value="toValue(currentRefinement, range)"
                          :marks="[range.min, range.max]"
                          :railStyle="{ backgroundColor: '#dfdfdf' }"
                          :processStyle="{ backgroundColor: '#0072ce' }"
                          :absorb="true"
                          :labelStyle="{
                            marginTop: '1.25em',
                            color: '#939393',
                            fontSize: '.9rem',
                          }"
                          ref="slider1"
                          :enableCross="false"
                          @drag-start="updateSlider1()"
                          @change="refine({ min: $event[0], max: $event[1] })"
                        >
                          <template v-slot:dot="{ focus }">
                            <div :class="['custom-dot', { focus }]"></div>
                          </template>
                        </vue-slider>
                      </div>
                    </ais-range-input>
                  </ais-panel>
                </div>

                <!-- Mileage Filter -->
                <div class="col-6 d-block d-lg-none">
                  <ais-panel>
                    <div class="rangeInputWrapper">
                      <h6>Mileage</h6>
                      <ais-range-input attribute="mileage">
                        <span slot="separator">-</span>
                      </ais-range-input>
                    </div>
                    <ais-range-input attribute="mileage">
                      <div slot-scope="{ currentRefinement, range, refine }">
                        <vue-slider
                          :tooltip="'none'"
                          :min="range.min"
                          :max="range.max"
                          :lazy="false"
                          :contained="true"
                          :value="toValue(currentRefinement, range)"
                          :marks="[range.min, range.max]"
                          :railStyle="{ backgroundColor: '#dfdfdf' }"
                          :processStyle="{ backgroundColor: '#0072ce' }"
                          :absorb="true"
                          :labelStyle="{
                            marginTop: '1.25em',
                            color: '#939393',
                            fontSize: '.9rem',
                          }"
                          ref="slider5"
                          :enableCross="false"
                          @drag-start="updateSlider5()"
                          @change="refine({ min: $event[0], max: $event[1] })"
                        >
                          <template v-slot:dot="{ focus }">
                            <div :class="['custom-dot', { focus }]"></div>
                          </template>
                        </vue-slider>
                      </div>
                    </ais-range-input>
                  </ais-panel>
                </div>

                <hr class="divider d-block d-lg-none" />

                <!-- MPG (HWY) Filter -->
                <div class="col-6">
                  <ais-panel>
                    <div class="rangeInputWrapper">
                      <h6>MPG (HWY)</h6>
                      <ais-range-input attribute="highway_mpg">
                        <span slot="separator">-</span>
                      </ais-range-input>
                    </div>
                    <ais-range-input attribute="highway_mpg">
                      <div slot-scope="{ currentRefinement, range, refine }">
                        <vue-slider
                          :tooltip="'none'"
                          :min="range.min"
                          :max="range.max"
                          :lazy="false"
                          :contained="true"
                          :value="toValue(currentRefinement, range)"
                          :marks="[range.min, range.max]"
                          :railStyle="{ backgroundColor: '#dfdfdf' }"
                          :processStyle="{ backgroundColor: '#0072ce' }"
                          :absorb="true"
                          :labelStyle="{
                            marginTop: '1.25em',
                            color: '#939393',
                            fontSize: '.9rem',
                          }"
                          ref="slider3"
                          :enableCross="false"
                          @drag-start="updateSlider3()"
                          @change="refine({ min: $event[0], max: $event[1] })"
                        >
                          <template v-slot:dot="{ focus }">
                            <div :class="['custom-dot', { focus }]"></div>
                          </template>
                        </vue-slider>
                      </div>
                    </ais-range-input>
                  </ais-panel>
                </div>

                <!-- MPG (CITY) Filter -->
                <div class="col-6">
                  <ais-panel>
                    <div class="rangeInputWrapper">
                      <h6>MPG (CITY)</h6>
                      <ais-range-input attribute="city_mpg">
                        <span slot="separator">-</span>
                      </ais-range-input>
                    </div>
                    <ais-range-input attribute="city_mpg">
                      <div slot-scope="{ currentRefinement, range, refine }">
                        <vue-slider
                          :tooltip="'none'"
                          :min="range.min"
                          :max="range.max"
                          :lazy="false"
                          :contained="true"
                          :value="toValue(currentRefinement, range)"
                          :marks="[range.min, range.max]"
                          :railStyle="{ backgroundColor: '#dfdfdf' }"
                          :processStyle="{ backgroundColor: '#0072ce' }"
                          :absorb="true"
                          :labelStyle="{
                            marginTop: '1.25em',
                            color: '#939393',
                            fontSize: '.9rem',
                          }"
                          ref="slider4"
                          :enableCross="false"
                          @drag-start="updateSlider4()"
                          @change="refine({ min: $event[0], max: $event[1] })"
                        >
                          <template v-slot:dot="{ focus }">
                            <div :class="['custom-dot', { focus }]"></div>
                          </template>
                        </vue-slider>
                      </div>
                    </ais-range-input>
                  </ais-panel>
                </div>

                <hr class="divider" />

                <!-- Drive Train Filter -->
                <div class="col col-xl-4">
                  <ais-panel>
                    <template v-slot:header>
                      <h6>Drive Train</h6>
                    </template>
                    <template v-slot:default>
                      <ais-refinement-list attribute="drivetrain_description">
                        <ul
                          slot-scope="{ items, refine, createURL }"
                          class="ais-RefinementList-list"
                        >
                          <li
                            v-for="item in items"
                            :key="item.value"
                            class="ais-RefinementList-item"
                            :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                          >
                            <a
                              class="ais-RefinementList-checkbox"
                              :href="createURL( item.value )"
                              :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                              @click.prevent="refine( item.value )"
                            >
                              <span class="ais-RefinementList-label">{{ item.label }}</span>
                              <span class="ais-RefinementList-count">{{ item.count }}</span>
                            </a>
                          </li>
                        </ul>
                      </ais-refinement-list>
                    </template>
                  </ais-panel>
                </div>

                <!-- Cylinders Filter -->
                <div class="col col-xl-4">
                  <ais-panel>
                    <template v-slot:header>
                      <h6>Cylinders</h6>
                    </template>
                    <template v-slot:default>
                      <ais-refinement-list attribute="cylinders">
                        <ul
                          slot-scope="{ items, refine, createURL }"
                          class="ais-RefinementList-list"
                        >
                          <li
                            v-for="item in items"
                            :key="item.value"
                            class="ais-RefinementList-item"
                            :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                          >
                            <a
                              class="ais-RefinementList-checkbox"
                              :href="createURL( item.value )"
                              :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                              @click.prevent="refine( item.value )"
                            >
                              <span class="ais-RefinementList-label">{{ item.label }}</span>
                              <span class="ais-RefinementList-count">{{ item.count }}</span>
                            </a>
                          </li>
                        </ul>
                      </ais-refinement-list>
                    </template>
                  </ais-panel>
                </div>

                <!-- Door Count Filter -->
                <div class="col col-xl-4">
                  <ais-panel>
                    <template v-slot:header>
                      <h6>Door Count</h6>
                    </template>
                    <template v-slot:default>
                      <ais-refinement-list attribute="door_count">
                        <ul
                          slot-scope="{ items, refine, createURL }"
                          class="ais-RefinementList-list"
                        >
                          <li
                            v-for="item in items"
                            :key="item.value"
                            class="ais-RefinementList-item"
                            :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                          >
                            <a
                              class="ais-RefinementList-checkbox"
                              :href="createURL( item.value )"
                              :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                              @click.prevent="refine( item.value )"
                            >
                              <span class="ais-RefinementList-label">{{ item.label }}</span>
                              <span class="ais-RefinementList-count">{{ item.count }}</span>
                            </a>
                          </li>
                        </ul>
                      </ais-refinement-list>
                    </template>
                  </ais-panel>
                </div>

                <hr class="divider" />

                <!-- Color Filter -->
                <div class="col col-xl-6">
                  <ais-panel>
                    <template v-slot:header>
                      <h6>Color</h6>
                    </template>
                    <template v-slot:default>
                      <ais-refinement-list
                        attribute="color"
                        :transform-items="transformColors"
                      >
                        <ul
                          slot-scope="{ items, refine, createURL }"
                          class="ais-RefinementList-list"
                        >
                          <li
                            v-for="item in items"
                            :key="item.value"
                            class="ais-RefinementList-item colorItem"
                            :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                          >
                            <a
                              class="ais-RefinementList-checkbox colorLink"
                              :href="createURL(item.value)"
                              @click.prevent="refine(item.value)"
                            >
                              <div
                                v-if="item.label.includes('White')"
                                class="ais-RefinementList-labelText whiteLabel"
                              >
                                <div class="swatch"></div>
                                White
                              </div>
                              <div
                                v-if="item.label.includes('Black')"
                                class="ais-RefinementList-labelText blackLabel"
                              >
                                <div class="swatch"></div>
                                Black
                              </div>
                              <div
                                v-if="item.label.includes('Red')"
                                class="ais-RefinementList-labelText redLabel"
                              >
                                <div class="swatch"></div>
                                Red
                              </div>
                              <div
                                v-if="item.label.includes('Silver')"
                                class="ais-RefinementList-labelText silverLabel"
                              >
                                <div class="swatch"></div>
                                Silver
                              </div>
                              <div
                                v-if="item.label.includes('Blue')"
                                class="ais-RefinementList-labelText blueLabel"
                              >
                                <div class="swatch"></div>
                                Blue
                              </div>
                              <div
                                v-if="item.label.includes('Gray')"
                                class="ais-RefinementList-labelText grayLabel"
                              >
                                <div class="swatch"></div>
                                Gray
                              </div>
                              <div
                                v-if="item.label.includes('Steel')"
                                class="ais-RefinementList-labelText steelLabel"
                              >
                                <div class="swatch"></div>
                                Steel
                              </div>
                              <span class="ais-RefinementList-count">
                                {{ item.count }}
                              </span>
                            </a>
                          </li>
                        </ul>
                      </ais-refinement-list>
                    </template>
                  </ais-panel>
                </div>

                <!-- Interior Material Filter -->
                <div class="col col-xl-6">
                  <ais-panel>
                    <template v-slot:header>
                      <h6>Interior Material</h6>
                    </template>
                    <template v-slot:default>
                      <ais-refinement-list attribute="interior_material">
                        <ul
                          slot-scope="{ items, refine, createURL }"
                          class="ais-RefinementList-list"
                        >
                          <li
                            v-for="item in items"
                            :key="item.value"
                            class="ais-RefinementList-item"
                            :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                          >
                            <a
                              class="ais-RefinementList-checkbox"
                              :href="createURL( item.value )"
                              :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                              @click.prevent="refine( item.value )"
                            >
                              <span class="ais-RefinementList-label">{{ item.label }}</span>
                              <span class="ais-RefinementList-count">{{ item.count }}</span>
                            </a>
                          </li>
                        </ul>
                      </ais-refinement-list>
                    </template>
                  </ais-panel>
                </div>

                <hr class="divider" />

                <!-- Fuel and Trim Filter -->
                <div class="col">
                  <ais-panel>
                    <template v-slot:header>
                      <h6>Fuel Type</h6>
                    </template>
                    <template v-slot:default>
                      <ais-refinement-list attribute="fuel">
                        <ul
                          slot-scope="{ items, refine, createURL }"
                          class="ais-RefinementList-list"
                        >
                          <li
                            v-for="item in items"
                            :key="item.value"
                            class="ais-RefinementList-item"
                            :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                          >
                            <a
                              class="ais-RefinementList-checkbox"
                              :href="createURL( item.value )"
                              :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                              @click.prevent="refine( item.value )"
                            >
                              <span class="ais-RefinementList-label">{{ item.label }}</span>
                              <span class="ais-RefinementList-count">{{ item.count }}</span>
                            </a>
                          </li>
                        </ul>
                      </ais-refinement-list>
                    </template>
                  </ais-panel>
                </div>

                <!-- Transmission Filter -->
                <div class="col">
                  <ais-panel>
                    <template v-slot:header>
                      <h6>Transmission</h6>
                    </template>
                    <template v-slot:default>
                      <ais-refinement-list attribute="transmission">
                        <ul
                          slot-scope="{ items, refine, createURL }"
                          class="ais-RefinementList-list"
                        >
                          <li
                            v-for="item in items"
                            :key="item.value"
                            class="ais-RefinementList-item"
                            :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                          >
                            <a
                              class="ais-RefinementList-checkbox"
                              :href="createURL( item.value )"
                              :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                              @click.prevent="refine( item.value )"
                            >
                              <span class="ais-RefinementList-label">{{ item.label }}</span>
                              <span class="ais-RefinementList-count">{{ item.count }}</span>
                            </a>
                          </li>
                        </ul>
                      </ais-refinement-list>
                    </template>
                  </ais-panel>
                </div>

                <hr class="divider" />

                <!-- engine Filter -->
                <div class="col-12">
                  <ais-panel>
                    <template v-slot:header>
                      <h6>Engine</h6>
                    </template>
                    <template v-slot:default>
                      <ais-refinement-list attribute="engine">
                        <ul
                          slot-scope="{ items, refine, createURL }"
                          class="ais-RefinementList-list"
                        >
                          <li
                            v-for="item in items"
                            :key="item.value"
                            class="ais-RefinementList-item"
                            :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                          >
                            <a
                              class="ais-RefinementList-checkbox"
                              :href="createURL( item.value )"
                              :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                              @click.prevent="refine( item.value )"
                            >
                              <span class="ais-RefinementList-label">{{ item.label }}</span>
                              <span class="ais-RefinementList-count">{{ item.count }}</span>
                            </a>
                          </li>
                        </ul>
                      </ais-refinement-list>
                    </template>
                  </ais-panel>
                </div>

                <hr class="divider" />

                <!-- Features Filter -->
                <div class="col-12">
                  <ais-panel>
                    <template v-slot:header>
                      <h6>Features</h6>
                    </template>
                    <template v-slot:default>
                      <ais-refinement-list
                        :limit="8"
                        attribute="feature_list"
                        :show-more="true"
                        :show-more-limit="50"
                        :sort-by="['isRefined']"
                      >
                        <ul
                          slot-scope="{ items, refine, createURL }"
                          class="ais-RefinementList-list"
                        >
                          <li
                            v-for="item in items"
                            :key="item.value"
                            class="ais-RefinementList-item"
                            :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                          >
                            <a
                              class="ais-RefinementList-checkbox"
                              :href="createURL( item.value )"
                              :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                              @click.prevent="refine( item.value )"
                            >
                              <span class="ais-RefinementList-label">{{ item.label }}</span>
                              <span class="ais-RefinementList-count">{{ item.count }}</span>
                            </a>
                          </li>
                        </ul>
                      </ais-refinement-list>
                    </template>
                  </ais-panel>
                </div>
              </div>
            </div>
          </div>

          <!-- Show Filters Mobile -->
          <div class="dropdown filterDropdown d-block d-lg-none showFilters">
            <button
              class="filterBtn"
              id="showFiltersTrigger"
              type="button"
              data-target="#showFiltersModal"
              data-toggle="modal"
              @click="scrollToFilter()"
            >
              Filters
            </button>
          </div>

          <!-- Mobile Modal -->
          <div
            class="modal fade p-0"
            id="showFiltersModal"
            tabindex="-1"
            aria-labelledby="showFiltersTrigger"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Filters
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="accordion" id="accordionExample">
                    <!-- Certification Filter Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="certificationFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#certificationFilter"
                            aria-expanded="false"
                            aria-controls="certificationFilter"
                          >
                            Certification
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="certificationFilter"
                        class="collapse"
                        aria-labelledby="certificationFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list
                                attribute="certification"
                                :transform-items="transformCertification"
                              >
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list certified-filter-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{
                                      'ais-RefinementList-item--selected': item.isRefined,
                                    }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL(item.value)"
                                      :style="{
                                        fontWeight: item.isRefined
                                          ? 'bold'
                                          : '',
                                      }"
                                      @click.prevent="refine(item.value)"
                                    >
                                      <span
                                        v-if="
                                          item.label == 'Certified Pre-Owned'
                                        "
                                        class="ais-RefinementList-label"
                                        >Certified Pre-Owned</span
                                      >
                                      <span
                                        v-else-if="
                                          item.label ==
                                            'Clawson Certified Pre-Owned'
                                        "
                                        class="ais-RefinementList-label"
                                        >Clawson Certified Pre-Owned</span
                                      >
                                      <span
                                        v-else-if="
                                          item.label == 'Clawson Customs'
                                        "
                                        class="ais-RefinementList-label"
                                        >Clawson Customs</span
                                      >
                                      <span
                                        v-else-if="
                                          item.label == 'HondaTrue Used'
                                        "
                                        class="ais-RefinementList-label"
                                        >HondaTrue Used</span
                                      >
                                      <span
                                        v-else-if="
                                          item.label == 'HondaTrue Certified'
                                        "
                                        class="ais-RefinementList-label"
                                        >HondaTrue Certified</span
                                      >
                                      <span
                                        v-else-if="
                                          item.label == 'HondaTrue Certified+'
                                        "
                                        class="ais-RefinementList-label"
                                        >HondaTrue Certified+</span
                                      >
                                      <span class="ais-RefinementList-count">{{
                                        item.count
                                      }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                        </div>
                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Make Filter Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="makeFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#makeFilter"
                            aria-expanded="false"
                            aria-controls="makeFilter"
                          >
                            Make, Model & Trim
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="makeFilter"
                        class="collapse"
                        aria-labelledby="makeFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel class="mb-4">
                            <template v-slot:header>
                              <h6>Make</h6>
                            </template>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list attribute="make">
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                          <hr class="divider" />
                          <ais-panel class="mb-4">
                            <template v-slot:header>
                              <h6>Model</h6>
                            </template>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list attribute="model" :limit="40">
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                          <hr class="divider" />
                          <ais-panel>
                            <template v-slot:header>
                              <h6>Trim</h6>
                            </template>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list attribute="trim">
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                        </div>
                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Price Filter Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="priceFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#priceFilter"
                            aria-expanded="false"
                            aria-controls="priceFilter"
                          >
                            Price
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="priceFilter"
                        class="collapse"
                        aria-labelledby="priceFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <asi-panel>
                            <div class="rangeInputWrapper">
                              <h6>Price</h6>
                              <ais-range-input attribute="price">
                                <span slot="separator">-</span>
                              </ais-range-input>
                            </div>
                            <ais-range-input attribute="price">
                              <div
                                slot-scope="{
                                  currentRefinement,
                                  range,
                                  refine,
                                }"
                              >
                                <vue-slider
                                  :tooltip="'none'"
                                  :min="range.min"
                                  :max="range.max"
                                  :lazy="false"
                                  :contained="true"
                                  :value="toValue(currentRefinement, range)"
                                  :marks="[range.min, range.max]"
                                  :railStyle="{ backgroundColor: '#dfdfdf' }"
                                  :processStyle="{ backgroundColor: '#0072ce' }"
                                  :labelStyle="{
                                    marginTop: '1.25em',
                                    color: '#939393',
                                    fontSize: '.9rem',
                                  }"
                                  ref="slider2"
                                  :enableCross="false"
                                  @drag-start="updateSlider2()"
                                  @change="
                                    refine({ min: $event[0], max: $event[1] })
                                  "
                                >
                                  <template v-slot:dot="{ focus }">
                                    <div
                                      :class="['custom-dot', { focus }]"
                                    ></div>
                                  </template>
                                </vue-slider>
                              </div>
                            </ais-range-input>
                          </asi-panel>
                        </div>
                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Body Filter Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="bodyFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#bodyFilter"
                            aria-expanded="false"
                            aria-controls="bodyFilter"
                          >
                            Body
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="bodyFilter"
                        class="collapse"
                        aria-labelledby="bodyFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list attribute="body">
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                        </div>

                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Year Filter Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="yearFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#yearFilter"
                            aria-expanded="false"
                            aria-controls="yearFilter"
                          >
                            Year
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="yearFilter"
                        class="collapse"
                        aria-labelledby="yearFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <div class="rangeInputWrapper">
                              <h6>Year</h6>
                              <ais-range-input attribute="year">
                                <span slot="separator">-</span>
                              </ais-range-input>
                            </div>
                            <ais-range-input attribute="year">
                              <div
                                slot-scope="{
                                  currentRefinement,
                                  range,
                                  refine,
                                }"
                              >
                                <vue-slider
                                  :tooltip="'none'"
                                  :min="range.min"
                                  :max="range.max"
                                  :lazy="false"
                                  :contained="true"
                                  :value="toValue(currentRefinement, range)"
                                  :marks="[range.min, range.max]"
                                  :railStyle="{ backgroundColor: '#dfdfdf' }"
                                  :processStyle="{ backgroundColor: '#0072ce' }"
                                  :absorb="true"
                                  :labelStyle="{
                                    marginTop: '1.25em',
                                    color: '#939393',
                                    fontSize: '.9rem',
                                  }"
                                  ref="slider1"
                                  :enableCross="false"
                                  @drag-start="updateSlider1()"
                                  @change="
                                    refine({ min: $event[0], max: $event[1] })
                                  "
                                >
                                  <template v-slot:dot="{ focus }">
                                    <div
                                      :class="['custom-dot', { focus }]"
                                    ></div>
                                  </template>
                                </vue-slider>
                              </div>
                            </ais-range-input>
                          </ais-panel>
                        </div>
                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Fuel Type Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="fuelTypeFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#fuelTypeFilter"
                            aria-expanded="false"
                            aria-controls="fuelTypeFilter"
                          >
                            Fuel Type
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="fuelTypeFilter"
                        class="collapse"
                        aria-labelledby="fuelTypeFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list attribute="fuel">
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                        </div>

                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Mpg (HWY) Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="mpgHWYFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#mpgHWYFilter"
                            aria-expanded="false"
                            aria-controls="mpgHWYFilter"
                          >
                            MPG (HWY)
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="mpgHWYFilter"
                        class="collapse"
                        aria-labelledby="mpgHWYFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <div class="rangeInputWrapper">
                              <h6>MPG (HWY)</h6>
                              <ais-range-input attribute="highway_mpg">
                                <span slot="separator">-</span>
                              </ais-range-input>
                            </div>
                            <ais-range-input attribute="highway_mpg">
                              <div
                                slot-scope="{
                                  currentRefinement,
                                  range,
                                  refine,
                                }"
                              >
                                <vue-slider
                                  :tooltip="'none'"
                                  :min="range.min"
                                  :max="range.max"
                                  :lazy="false"
                                  :contained="true"
                                  :value="toValue(currentRefinement, range)"
                                  :marks="[range.min, range.max]"
                                  :railStyle="{ backgroundColor: '#dfdfdf' }"
                                  :processStyle="{ backgroundColor: '#0072ce' }"
                                  :absorb="true"
                                  :labelStyle="{
                                    marginTop: '1.25em',
                                    color: '#939393',
                                    fontSize: '.9rem',
                                  }"
                                  ref="slider3"
                                  :enableCross="false"
                                  @drag-start="updateSlider3()"
                                  @change="
                                    refine({ min: $event[0], max: $event[1] })
                                  "
                                >
                                  <template v-slot:dot="{ focus }">
                                    <div
                                      :class="['custom-dot', { focus }]"
                                    ></div>
                                  </template>
                                </vue-slider>
                              </div>
                            </ais-range-input>
                          </ais-panel>
                        </div>

                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Mpg (City) Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="mpgCITYFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#mpgCITYFilter"
                            aria-expanded="false"
                            aria-controls="mpgCITYFilter"
                          >
                            MPG (CITY)
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="mpgCITYFilter"
                        class="collapse"
                        aria-labelledby="mpgCITYFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <div class="rangeInputWrapper">
                              <h6>MPG (CITY)</h6>
                              <ais-range-input attribute="city_mpg">
                                <span slot="separator">-</span>
                              </ais-range-input>
                            </div>
                            <ais-range-input attribute="city_mpg">
                              <div
                                slot-scope="{
                                  currentRefinement,
                                  range,
                                  refine,
                                }"
                              >
                                <vue-slider
                                  :tooltip="'none'"
                                  :min="range.min"
                                  :max="range.max"
                                  :lazy="false"
                                  :contained="true"
                                  :value="toValue(currentRefinement, range)"
                                  :marks="[range.min, range.max]"
                                  :railStyle="{ backgroundColor: '#dfdfdf' }"
                                  :processStyle="{ backgroundColor: '#0072ce' }"
                                  :absorb="true"
                                  :labelStyle="{
                                    marginTop: '1.25em',
                                    color: '#939393',
                                    fontSize: '.9rem',
                                  }"
                                  ref="slider4"
                                  :enableCross="false"
                                  @drag-start="updateSlider4()"
                                  @change="
                                    refine({ min: $event[0], max: $event[1] })
                                  "
                                >
                                  <template v-slot:dot="{ focus }">
                                    <div
                                      :class="['custom-dot', { focus }]"
                                    ></div>
                                  </template>
                                </vue-slider>
                              </div>
                            </ais-range-input>
                          </ais-panel>
                        </div>

                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Features Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="featuresFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#featuresFilter"
                            aria-expanded="false"
                            aria-controls="featuresFilter"
                          >
                            Features
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="featuresFilter"
                        class="collapse"
                        aria-labelledby="featuresFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list
                                attribute="feature_list"
                                :sort-by="['isRefined']"
                                :limit="8"
                                :show-more="true"
                                :show-more-limit="50"
                              >
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                        </div>
                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Drive Train Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="driveTrainFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#driveTrainFilter"
                            aria-expanded="false"
                            aria-controls="driveTrainFilter"
                          >
                            Drive Train
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="driveTrainFilter"
                        class="collapse"
                        aria-labelledby="driveTrainFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list attribute="drivetrain_description">
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                        </div>
                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Cylinders Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="cylindersFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#cylindersFilter"
                            aria-expanded="false"
                            aria-controls="cylindersFilter"
                          >
                            Cylinders
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="cylindersFilter"
                        class="collapse"
                        aria-labelledby="cylindersFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list attribute="cylinders">
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                        </div>
                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Door Count Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="doorCountFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#doorCountFilter"
                            aria-expanded="false"
                            aria-controls="doorCountFilter"
                          >
                            Door Count
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="doorCountFilter"
                        class="collapse"
                        aria-labelledby="doorCountFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list attribute="door_count">
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                        </div>
                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Transmission Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="transmissionFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#transmissionFilter"
                            aria-expanded="false"
                            aria-controls="transmissionFilter"
                          >
                            Transmission
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="transmissionFilter"
                        class="collapse"
                        aria-labelledby="transmissionFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list attribute="transmission">
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                        </div>
                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Engine Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="engineFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#engineFilter"
                            aria-expanded="false"
                            aria-controls="engineFilter"
                          >
                            Engine
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="engineFilter"
                        class="collapse"
                        aria-labelledby="engineFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list attribute="engine">
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                        </div>
                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Interior Material Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="InteriorMaterialHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#interiorMaterialFilter"
                            aria-expanded="false"
                            aria-controls="interiorMaterialFilter"
                          >
                            Interior Material
                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="interiorMaterialFilter"
                        class="collapse"
                        aria-labelledby="interiorMaterialHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list attribute="interior_material">
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing ot Filter</p>
                            </template>
                          </ais-panel>
                        </div>
                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Color Mobile -->
                    <div class="card filter">
                      <div class="card-header" id="colorFilterHeader">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#colorFilter"
                            aria-expanded="false"
                            aria-controls="colorFilter"
                          >
                            Color

                            <b-icon icon="chevron-down"></b-icon>
                          </button>
                        </h2>
                      </div>

                      <div
                        id="colorFilter"
                        class="collapse"
                        aria-labelledby="colorFilterHeader"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ais-panel>
                            <template v-slot:default="{ hasRefinements }">
                              <ais-refinement-list
                                attribute="color"
                                :transform-item="transformColors"
                              >
                                <ul
                                  slot-scope="{ items, refine, createURL }"
                                  class="ais-RefinementList-list"
                                >
                                  <li
                                    v-for="item in items"
                                    :key="item.value"
                                    class="ais-RefinementList-item"
                                    :class="{ 'ais-RefinementList-item--selected': item.isRefined }"
                                  >
                                    <a
                                      class="ais-RefinementList-checkbox"
                                      :href="createURL( item.value )"
                                      :style="{ fontWeight: item.isRefined ? 'bold' : '', }"
                                      @click.prevent="refine( item.value )"
                                    >
                                      <span class="ais-RefinementList-label">{{ item.label }}</span>
                                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                                    </a>
                                  </li>
                                </ul>
                              </ais-refinement-list>
                              <p v-if="!hasRefinements">Nothing to Filter</p>
                            </template>
                          </ais-panel>
                        </div>
                        <div class="btnHolster">
                          <button
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            class="btn btn-block btn-primary"
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Active and Clear Filters -->
        <div
          class="row mb-2 mt-2 mt-sm-3 justify-content-between"
          id="refineLabels"
        >
          <ais-current-refinements :excluded-attributes="['new']">
            <template v-slot="{ items, refine, createURL }">
              <ul class="ais-currentRefinements-list">
                <li v-for="item in items" :key="item.attribute" class="ais-currentRefinements-attList">
                  <button
                    v-for="( refinement, index ) in item.refinements"
                    class="ais-currentrefinements-delete ais-currentRefinements-item"
                    :key="[refinement.attribute,refinement.type,refinement.value,refinement.operator].join(':')"
                    :href="createURL(refinement)"
                    @click.prevent="refine(refinement)"
                  >
                    <span class="ais-currentRefinements-label">
                      {{ transformCurrentRefinementLabel( refinement ) }}
                      <span v-if="index == 0 && index + 1 == item.refinements.length">
                        {{ getRefinementOperator( refinement ) }}
                      </span>
                      <span v-if="index + 1 == item.refinements.length" class="ml-1">
                        <span v-if="refinement.attribute == 'highway_mpg'">MPG (HWY)</span>
                        <span v-if="refinement.attribute == 'city_mpg'">MPG (CITY)</span>
                      </span>
                      X
                    </span>
                  </button>
                </li>
              </ul>
            </template>
          </ais-current-refinements>
        </div>

        <!-- Stats -->
        <div class="row justify-content-between">
          <ais-stats ref="statsRef" />
          <ais-clear-refinements
            :excluded-attributes="['new']"
            :class-names="{
              'ais-ClearRefinements-button':
                'clearFiltersBtn btn btn-sm btn-link p-0',
            }"
          >
            <span slot="resetLabel">Clear Filters</span>
          </ais-clear-refinements>
        </div>

        <div class="row">
          <ais-state-results class="w-100">
            <template v-slot="{ results: { hits, query } }">
              <ais-hits v-if="hits.length > 0" ref="hitsRef">
                <div slot="item" slot-scope="{ item }">
                  <vehicle-card
                    :carid="item.objectID"
                    :car="item"
                  ></vehicle-card>
                </div>
              </ais-hits>
              <div class="mt-5" v-else>
                <p class="h3">No results have been found for {{ query }}</p>
                .
              </div>
            </template>
          </ais-state-results>
        </div>

        <ais-pagination class="mt-4" />
      </div>

      <div class="disclaimer-section container mt-5">
        <p class="disclaimer row">
          *Based on 2021 EPA mileage ratings. Use for comparison purposes only.
          Your mileage will vary depending on how you drive and maintain your
          vehicle, driving conditions and other factors.
        </p>
        <p class="disclaimer row">
          **Based on 2021 EPA mileage ratings. Use for comparison purposes only.
          Your mileage will vary depending on how you drive and maintain your
          vehicle, driving conditions, battery-pack age/condition (hybrid trims)
          and other factors.
        </p>
        <hr />
        <div class="disclaimer row">
          <p>
            New vehicle pricing includes all offers and incentives. Tax,
            Title, and Tags are not included in vehicle prices shown and must
            be paid by the purchaser. While great effort is made to ensure the
            accuracy of the information on this site, errors do occur so
            please verify information with a customer service rep. This is
            easily done by calling us at 559-435-5000 or by visiting us at the
            dealership.
          </p>
          <p>
            New and used prices do not include government fees, and taxes, any
            finance charges, any dealer document processing charge, any
            electronic filing charge, dealer installed accessories, and any
            emission testing charge.
          </p>
          <p>
            **With approved credit. Terms may vary. Monthly payments are only
            estimates derived from the vehicle price with a 72-month term,
            4.9% interest, and 20% down payment.
          </p>
          <p>
            Some of our used vehicles may be subject to unrepaired safety
            recalls. Check for a vehicle’s unrepaired recalls by VIN at
            <a href="https://vinrcl.safecar.gov/vin/"
              >Check for Recalls Here</a
            >
          </p>
          <p>
            All prices are plus government fees and taxes, any finance
            charges, any dealer document processing charge, any electronic
            filing charge, and any emissions testing charge.
          </p>
          <p>
            *MPG on non-hybrid vehicles based on EPA mileage ratings. Use for
            comparison purposes only. Your mileage will vary depending on how
            you drive and maintain your vehicle, driving conditions, and other
            factors.
          </p>
          <p>
            *MPG for hybrid vehicles based on EPA mileage ratings. Use for
            comparison purposes only. Your mileage will vary depending on how
            you drive and maintain your vehicle, driving conditions,
            battery-pack age/condition (hybrid trims), and other factors.
          </p>
          <p>
            Any image of a stock photo may not reflect the model year or color
            of, and/or equipment offered on, the advertised vehicle. The image
            is shown for illustration purposes only.
          </p>
          <p>
            Engine for Life Warranty covers all Clawson Certified Pre-Owned
            units and is available for purchase on all current, plus 10 prior
            model years under 100,000 miles. For more information go to
            https://clawsonhonda.com/engine-life-warranty/
          </p>
          <p>
            Proposition 65 Warning: Operating, servicing, and maintaining a
            passenger vehicle or off-road vehicle can expose you to chemicals
            including engine exhaust, carbon monoxide, phthalates, and lead,
            which are known to the State of California to cause cancer and
            birth defects or other reproductive harm. To minimize exposure,
            avoid breathing exhaust, do not idle the engine except as
            necessary, service your vehicle in a well-ventilated area, and
            wear gloves or wash your hands frequently when servicing your
            vehicle. For more information go to
            <a href="https://www.p65warnings.ca.gov/products/passenger-vehicle/">
              p65Warnings
            </a>
          </p>
        </div>
      </div>
    </div>
    <!-- /container-fluid -->
  </ais-instant-search>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import VueSlider from "vue-slider-component";
import VehicleCardComponent from "./VehicleCardComponent.vue";
import RelatedVehiclesComponent from "./RelatedVehiclesComponent.vue";
import VehicleImagesComponent from "./VehicleImagesComponent.vue";
import { BootstrapVue } from "bootstrap-vue";
import algoliasearchHelper, * as algoliaSearchHelper from "algoliasearch-helper";
import { routingLogic } from "./routing";
import CONFIG from "config";
import { yearList, priceRangeList, mileageRangeList } from "../helpers";
// console.log(ALGOLIA_INDEX);
const algoliaClient = algoliasearch(
  "KH283DNHYF",
  "fea66231fa39a1c8326ac94f0252a75c"
);

const searchClient = {
  search(requests) {
    let searchClient = algoliaClient.search(requests);
    let filterBtns = document.querySelectorAll("button.filterBtn");
    let certDropdown = document.getElementById("certificationFilterTrigger");
    let page = 0;

    searchClient
      .then(({ results }) => {
        let hits = results[0];
        let newPage = hits.page;

        if (page != newPage) {
          if (screen.width <= 992) {
            document.body.scroll(0, 0);
          } else {
            window.scroll(0, 0);
          }
        }

        if (hits.nbHits == 0) {
          filterBtns.forEach((btn, index) => {
            btn.disabled = true;
            btn.style.opacity = 0.6;
          });
        }

        if (hits.nbHits > 0) {
          filterBtns.forEach((btn, index) => {
            btn.disabled = false;
            btn.style.opacity = 1;
          });
        }

        if ("certification" in hits["facets"]) {
          certDropdown.disabled = false;
          certDropdown.style.opacity = 1;
        } else if (!("certification" in hits["facets"])) {
          certDropdown.disabled = true;
          certDropdown.style.opacity = 0.6;
        }
      })
      .catch((err) => console.error(err));

    return searchClient;
  },
};

export default {
  components: {
    VueSlider,
    VehicleCardComponent,
    RelatedVehiclesComponent,
    VehicleImagesComponent,
    BootstrapVue,
  },
  data: function() {
    return {
      searchClient,
      indexName: CONFIG.ALGOLIA_INDEX,
      // query: "",
      filters: {},
      certFilter: 0,
      paramState: "",
      routing: routingLogic,
      years: yearList(),
      priceRange: priceRangeList,
      mileageRange: mileageRangeList,
      yearStart: 1990,
      yearEnd: 2021,
      noResults: false,
      calcFilters: false,
      conditionNew: false,
      cpo: false,
      shareLink: window.location.href,
      suggestOpenable: true,
      results: {},
      result_filters: {},
      filter_data: {
        features: "",
        transmissions: "",
        trims: "",
        conditions: ["Pre-Owned", "Certified Pre-Owned"],
      },
      detailsPage: {
        viewing: false,
        id: 0,
        data: {},
        finance: {
          taxTitleFees: 0,
          downPayment: 3000,
          totalAmount: 0,
          numOfMonths: 72,
          monthlyPayment: 0,
          interestRateNew: 3.99,
          interestRateUsed: 7.99,
        },
      },
      metaData: {
        numResults: 0,
      },
      scrollToTop: 0,
    };
  },

  beforeMount() {
    // force new cars are rendered by default.
    // also need to check if condition filter needs
    // to show
    setTimeout(() => {
      let params = new URLSearchParams(document.location.search.substring(1)),
        newParam = params.get("new"),
        queryParam = params.get("query"),
        certificationParam = params.get("certification"),
        fuelParam = params.get("fuel"),
        title,
        description;

      // if new param is not set, then set it initially.
      if (!newParam && !queryParam) {
        window.history.pushState("", "", "?new=true");
      }

      if (newParam && newParam.toLowerCase() === "false") {
        this.conditionNew = false;
        if ( fuelParam && ["electric%20fuel%20system", "gas%252felectric%20hybrid", "gasoline%252fmild%20electric%20hybrid", "plug-in%20electric%252fgas"].includes(fuelParam.toLowerCase())) {
          title = "Pre-Owned Electric & Hybrid Vehicles | Clawson Honda";
          description = "Buy or lease a pre-owned Honda electric or Honda hybrid vehicle today at Clawson Honda. Located in Fresno, CA, we'lll make sure you enjoy your next drive. "
        } else {
          if (
            certificationParam &&
            certificationParam.toLowerCase() === "clawson%2520customs"
          ) {
            title = "Custom Pre-Owned Vehicles for Sale | Honda Dealership near Clovis, CA";
            description = "Shop our inventory of custom pre-owned Honda vehicles at Clawson Honda in Fresno, CA. Contact us with questions or visit us for a test drive!"
            this.cpo = true;
          } else if (certificationParam) {
            title = "Certified Pre Owned Vehicles for Sale | Clawson Honda ";
            description = "Shop our inventory of certified pre-owned Honda vehicles at Clawson Honda in Fresno, CA. Contact us with questions or visit us for a test drive!"
            this.cpo = true;
          }
        }
        if ( title === undefined ) {
          title = "Pre-Owned Vehicles for Sale | Honda Dealership | Clovis, CA";
          description = "Buy or lease a pre-owned Honda vehicle at Clawson Honda of Fresno, CA. Shop our inventory of cars, sedans, trucks, and SUVs or visit our Honda dealership today!"
          this.cpo = false;
        }
      } else {
        this.conditionNew = true;
        if (fuelParam && ["electric%20fuel%20system", "gas%252felectric%20hybrid", "gasoline%252fmild%20electric%20hybrid", "plug-in%20electric%252fgas"].includes(fuelParam.toLowerCase())) {
          title = "Shop Honda Hybrid Vehicles for Sale | Clawson Honda Dealer";
          description = "Honda hybrid vehicles are for sale at our Honda dealership in Fresno, CA. Shop hybrid and enjoy an eco-friendly drive. Contact us for a great deal today!"
        } else {
          title = "New Honda Vehicles for Sale | Honda Dealership | Clovis, CA";
          description = "Shop our inventory of new Honda vehicles at Clawson Honda in Fresno, CA. Contact us with questions or visit us for a test drive!"
        }
      }
      document.title = title;
      const metaTag = document.createElement("meta");
      metaTag.name = "description";
      metaTag.content = description;
      document.head.appendChild(metaTag);
    }, 500);
  },

  mounted() {
    let params = new URLSearchParams(document.location.search.substring(1));
    let queryParam = params.get("query");

    $(".dropdown-menu").click(function(e) {
      e.stopPropagation();
    });
  },

  computed: {
    detailsAvailable() {
      return this.detailsPage.data.car != undefined;
    },
    isMobile() {
      return screen.width <= 768;
    },
    isTabletOrMobile() {
      return screen.width <= 992;
    },
  },

  methods: {
    rerender() {
      this.$forceUpdate();
    },

    updateSlider1() {
      this.$refs.slider1.refresh();
    },

    updateSlider2() {
      this.$refs.slider2.refresh();
    },

    updateSlider3() {
      this.$refs.slider3.refresh();
    },

    updateSlider4() {
      this.$refs.slider4.refresh();
    },

    updateSlider5() {
      this.$refs.slider5.refresh();
    },

    scrollPageTop() {
      if (this.isTabletOrMobile) {
        document.body.scroll(0, 0);
      } else {
        window.scroll(0, 0);
      }
    },

    formatMinValue(minValue, minRange) {
      return minValue !== null && minValue !== minRange ? minValue : "";
    },

    formatMaxValue(maxValue, maxRange) {
      return maxValue !== null && maxValue !== maxRange ? maxValue : "";
    },

    toValue(value, range) {
      return [
        value.min !== null ? value.min : range.min,
        value.max !== null ? value.max : range.max,
      ];
    },

    refineCondition(refine, value) {
      let refineFunc = refine;
      refineFunc(value);

      if (value === "true") {
        this.conditionNew = true;
      }

      if (value === "false") {
        this.conditionNew = false;
      }
      // this.conditionNew = !this.conditionNew;
    },

    refineCPO(refine, value) {
      let refineFunc = refine;
      refineFunc(value);

      if (value == "Yes") {
        this.cpo = true;
      }

      if (value == "") {
        this.cpo = false;
      }
    },

    closeDropdown(e) {
      let el = e.target;
      let $el = $(el);
      let $toggle = $el
        .closest(".dropdown-menu")
        .prev("button[data-toggle='dropdown']");
      $toggle.dropdown("toggle");
    },

    scrollToFilter() {
      let width = screen.availWidth;

      let filterPos = document.querySelector("#filterSection").offsetTop;

      if (width <= 768) {
        document.body.scroll({
          top: filterPos,
          behavior: "smooth",
        });
      }
    },

    transformCurrentRefinementLabel(refinement) {
      let newLabel;
      const { attribute:category, value } = refinement;

      switch (category) {
        case "new":
          if (value == "false") {
            newLabel = "Used";
          } else {
            newLabel = "New";
          }
          break;
        case "certified":
          newLabel = "CPO";
          break;
        case "price":
          newLabel = "$" + value.toLocaleString();
          break;
        case "door_count":
          newLabel = value + " doors";
          break;
        case "cylinders":
          newLabel = value + " cylinders";
          break;
        default:
          newLabel = value;
      }

      return newLabel;
    },

    transformTransmissions(items) {
      return items.filter((item) => {
        return (
          item.label == "CVT" ||
          item.label == "6-Speed Automatic" ||
          item.label == "6-Speed Manual"
        );
      });
    },

    transformFeatures(items) {
      let topFilters = [
        "Power moonroof",
        "Traction control",
        "Remote keyless entry",
        "fully automatic headlights",
        "Heated front seats",
        "Apple CarPlay / Android Auto",
        "Front fog lights",
        "Adaptive Cruise Control (ACC)",
      ];

      //let topFilters = ["Power moonroof"];
      // add items to top of item list
      return items.map((item) => {
        if (item.includes("Power moonroof")) {
          items[0] = "Power moonroof";
        }
      });
    },

    getRefinementOperator( refinement ) {
      const { operator, attribute } = refinement;
      let newOperator;
      switch ( attribute ) {
        case "year":
          if (operator == "<=") {
            newOperator = "or older";
          } else {
            newOperator = "or newer";
          }
          break;
        case "price":
        case "mileage":
        case "highway_mpg":
        case "city_mpg":
          if (operator == "<=") {
            newOperator = "or less";
          } else {
            newOperator = "or more";
          }
          break;
      }
      return newOperator;
    },

    addColorClass(element, color) {
      document.querySelector(element).classList.add("color-filter", color);
    },

    // only constrain to different colors
    // @todo: add class element to label.
    filterColors(label) {
      if (label.includes("White")) {
        return true;
      } else if (label.includes("Black")) {
        return true;
      } else if (label.includes("Red")) {
        return true;
      } else if (label.includes("Silver")) {
        return true;
      } else if (label.includes("Blue")) {
        return true;
      } else if (label.includes("Gray")) {
        return true;
      } else if (label.includes("Steel")) {
        return true;
      } else {
        return false;
      }
    },

    transformColors(items) {
      return items.filter((item) => {
        return this.filterColors(item.label);
      });
    },

    filterParams(params) {
      if (params.has("new")) {
        // filter new/used vehicles
      }
    },

    // closes suggest box for user to browse results
    // but not lose results.
    searchState() {
      this.suggestOpenable = false;
      document.querySelector(".header-search").blur();
      let params = new URLSearchParams(document.location.search.substring(1));
      let paramId = params.get("id");
      if (paramId) {
        this.detailsPage.viewing = false;
      }
    },

    suggestState() {
      this.suggestOpenable = true;
    },

    handleFocusOut() {
      // give state time to think whether or not
      // user clicked on item or if focused out of search.
      setTimeout(() => {
        this.suggestOpenable = false;
      }, 200);

      // this.$forceUpdate();
      // location.reload();
    },

    onSelect(selected) {
      if (selected) {
        this.query = selected.item.model;
      }
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({ data: hits }));
    },
  },

  // updated() {
  // 	let params = new URLSearchParams(document.location.search.substring(1)),
  //     newParam = params.get("new"),
  //     queryParam = params.get("query"),
  //     certifiedParam = params.get("certified"),
  // 		certificationParam = params.get("certification"),
  // 		title;

  //   if (!newParam && !queryParam) {
  //     window.history.pushState("", "", "?new=true");
  //   }
  //   if (newParam && newParam.toLowerCase() === "false") {
  //     this.conditionNew = false;
  // 		if (certifiedParam) {
  // 			if (certificationParam && certificationParam.toLowerCase() === "clawson customs") {
  // 				title = "Custom Pre-Owned Vehicles for Sale | Honda Dealership near Clovis, CA";
  // 			} else {
  // 				title = "Certified Pre-Owned Vehicles for Sale | Honda Dealership near Clovis, CA";
  // 			}
  // 			this.cpo = true;
  // 		} else {
  // 			title = "Pre-Owned Vehicles for Sale | Honda Dealership near Clovis, CA";
  // 			this.cpo = false;
  // 		}
  //   } else {
  //     this.conditionNew = true;
  // 		title = "New Honda Vehicles for Sale | Honda Dealership near Clovis, CA";
  //   }
  // 	document.title = title;
  // },

  filters: {
    formatPrices: function(number) {
      if (number != null) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
