import Vue from 'vue';
import SearchComponent from './search/SearchComponent.vue';
import newVehicleCardComponent from './search/newVehicleCardComponent.vue';
import RelatedVehiclesComponent from "./search/RelatedVehiclesComponent.vue";
import InstantSearch from 'vue-instantsearch';
import SearchInputComponent from './search/SearchInput.vue';
import VehicleImagesComponent from './search/VehicleImagesComponent.vue';
import VDPComponent from "./search/VDPComponent.vue";
import VDPCalculatorComponent from './search/VDPCalculatorComponent.vue';
import newSearchComponent from './search/newSearchComponent.vue';
import newVehicleDisplayPage from './search/newVehicleDisplayPage.vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueObserveVisibility from 'vue-observe-visibility';

window.Vue = Vue;
Vue.config.devtools = false;

Vue.use(VueObserveVisibility);
Vue.use(InstantSearch);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.component( 'vdp-calculator', VDPCalculatorComponent )
Vue.component( 'search-component', SearchComponent );
Vue.component( 'new-vehicle-card', newVehicleCardComponent );
Vue.component( 'related-vehicles', RelatedVehiclesComponent );
Vue.component( 'vehicle-images', VehicleImagesComponent );
Vue.component( 'vdp-page', VDPComponent );
Vue.component( 'new-vehicle-display-page', newVehicleDisplayPage );
Vue.component( 'new-search-component', newSearchComponent );
Vue.component( 'search-input', SearchInputComponent );

new Vue({
    el: '#clawson-app',
    data : () => ( {
        screenSize: 0,
    } ),
});
