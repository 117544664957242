<template>
  <ais-instant-search
    :index-name="indexName"
    :search-client="searchClient"
    :routing="routing"
  >
    <!-- SRP page  -->
    <div class="searchPage container-fluid pb-4">
      <div class="container page-container">
        <ais-configure :hitsPerPage="24" />

        <div class="row">
          <div id="autoHubBanner"></div>
        </div>

        <b-row class="mb-5">
          <b-col id="desktopMenu" class="d-none d-lg-block px-0" lg="2">
            <b-card class="sticky-menu">
              <b-row class="my-2">
                <b-col cols="12">
                  <ais-sort-by
                    attribute="sortBy"
                    class="clawson-sort-by"
                    :items="[
                      { value: indexName, label: 'Sort by' },
                      {
                        value: indexName + '_price_asc',
                        label: 'Price (Lowest)',
                      },
                      {
                        value: indexName + '_price_desc',
                        label: 'Price (Highest)',
                      },
                      {
                        value: indexName + '_year_desc',
                        label: 'Year (Newest)',
                      },
                      {
                        value: indexName + '_year_asc',
                        label: 'Year (Oldest)',
                      },
                      {
                        value: indexName + '_mileage_asc',
                        label: 'Mileage (Lowest)',
                      },
                      {
                        value: indexName + '_mileage_desc',
                        label: 'Mileage (Highest)',
                      },
                    ]"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="12">
                  <ais-search-box
                    placeholder="Search all results"
                    class="mt-3 mb-2 mb-sm-0 my-md-0"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="12">
                  <h2 class="clawson-filter-title mb-0">Filters</h2>
                  <ais-stats ref="statsRef">
                    <template v-slot="{ nbHits }">
                      <h3 class="clawson-filter-stats">{{ nbHits }} results</h3>
                    </template>
                  </ais-stats>
                  <ais-clear-refinements
                    :excluded-attributes="['new']"
                    :class-names="{
                      'ais-ClearRefinements-button':
                        'clearFiltersBtn btn btn-sm btn-link p-0',
                    }"
                  >
                    <span slot="resetLabel">Clear Filters</span>
                  </ais-clear-refinements>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="12" id="refineLabels">
                  <ais-current-refinements :excluded-attributes="['new']">
                    <template v-slot:default="{ items, createURL }">
                      <ul class="ais-currentRefinements-list">
                        <li
                          v-for="item in items"
                          :key="item.attribute"
                          class="ais-currentRefinements-attList"
                        >
                          <button
                            v-for="(refinement, index) in item.refinements"
                            class="ais-currentrefinements-delete ais-currentRefinements-item"
                            :key="
                              [
                                refinement.attribute,
                                refinement.type,
                                refinement.value,
                                refinement.operator,
                              ].join(':')
                            "
                            :href="createURL(refinement)"
                            @click.prevent="item.refine(refinement)"
                          >
                            <span class="ais-currentRefinements-label">
                              {{ transformCurrentRefinementLabel(refinement) }}
                              <span
                                v-if="
                                  index == 0 &&
                                  index + 1 == item.refinements.length
                                "
                              >
                                {{ getRefinementOperator(refinement) }}
                              </span>
                              <span
                                v-if="index + 1 == item.refinements.length"
                                class="ml-1"
                              >
                                <span
                                  v-if="refinement.attribute == 'highway_mpg'"
                                  >MPG (HWY)</span
                                >
                                <span v-if="refinement.attribute == 'city_mpg'"
                                  >MPG (CITY)</span
                                >
                              </span>
                              <b-icon icon="x"></b-icon>
                            </span>
                          </button>
                        </li>
                      </ul>
                    </template>
                  </ais-current-refinements>
                </b-col>
              </b-row>
              <!-- new/used buttons -->
              <b-row class="mb-2">
                <b-col cols="12">
                  <ais-menu
                    attribute="new"
                    class="newFilterMenu"
                    :sort-by="['name:desc']"
                    :transform-items="transformMenu"
                  >
                    <template v-slot:default="{ items, refine, createURL }">
                      <ul class="condition-new-filters">
                        <li v-for="item in items" :key="item.value">
                          <a
                            :href="createURL(item.value)"
                            :style="{
                              fontWeight: item.isRefined ? 'bold' : '',
                              background: item.isRefined ? '#0072CE' : '',
                              color: item.isRefined ? '#FFFFFF' : '#0072CE',
                              pointerEvents: item.isRefined ? 'none' : 'all',
                            }"
                            @click.prevent="refine(item.value)"
                          >
                            {{ item.label }}
                            <b-badge
                              pill
                              class="count ml-2"
                              :variant="!item.isRefined ? 'primary' : 'light'"
                              >{{ item.count }}
                              <span class="sr-only"
                                >{{ item.label }} vehicle count</span
                              ></b-badge
                            >
                          </a>
                        </li>
                      </ul>
                    </template>
                  </ais-menu>
                </b-col>
              </b-row>
              <hr />
              <!-- certification list -->
              <refinement-list
                title="Certification"
                :attrs="[{ name: 'certification' }]"
              ></refinement-list>
              <hr />
              <!-- make and model lists -->
              <refinement-list
                title="Make & Model"
                :attrs="[
                  {
                    name: 'make',
                    header: true,
                  },
                  {
                    name: 'model',
                    header: true,
                  },
                ]"
              ></refinement-list>
              <hr />
              <!-- trim list -->
              <refinement-list
                title="Trim"
                :attrs="[{ name: 'trim' }]"
              ></refinement-list>
              <hr />
              <!-- Price Slider -->
              <vue-slider-comp
                title="Price"
                :attrs="[{ name: 'price' }]"
              ></vue-slider-comp>
              <hr />
              <!-- Year and Mileage Sliders -->
              <vue-slider-comp
                title="Year & Mileage"
                :attrs="[
                  {
                    name: 'year',
                    header: true,
                  },
                  {
                    name: 'mileage',
                    header: true,
                  },
                ]"
              ></vue-slider-comp>
              <hr />
              <!-- Body Type list -->
              <refinement-list
                title="Body Type"
                :attrs="[{ name: 'body' }]"
              ></refinement-list>
              <hr />
              <!-- MPG Sliders -->
              <vue-slider-comp
                title="Miles Per Gallon"
                :attrs="[
                  {
                    name: 'highway_mpg',
                    header: true,
                  },
                  {
                    name: 'city_mpg',
                    header: true,
                  },
                ]"
              ></vue-slider-comp>
              <hr />
              <!-- Drivetrain list -->
              <refinement-list
                title="Drive Train"
                :attrs="[{ name: 'drivetrain_description' }]"
              ></refinement-list>
              <hr />
              <!-- Cylinders List -->
              <refinement-list
                title="Cylinders"
                :attrs="[{ name: 'cylinders' }]"
              ></refinement-list>
              <hr />
              <!-- Door Count List -->
              <refinement-list
                title="Door Count"
                :attrs="[{ name: 'door_count' }]"
              ></refinement-list>
              <hr />
              <!-- Interior Material List -->
              <refinement-list
                title="Interior Material"
                :attrs="[{ name: 'interior_material' }]"
              ></refinement-list>
              <hr />
              <!-- Color List -->
              <refinement-list
                title="Exterior Color"
                :attrs="[{ name: 'color', colorItem: true }]"
              ></refinement-list>
              <hr />
              <!-- Fuel Type & Engine Lists -->
              <refinement-list
                title="Fuel Type & Engine"
                :attrs="[
                  {
                    name: 'fuel',
                    header: true,
                  },
                  {
                    name: 'engine',
                    header: true,
                  },
                ]"
              ></refinement-list>
              <hr />
              <!-- Transmission List -->
              <refinement-list
                title="Transmission"
                :attrs="[{ name: 'transmission' }]"
              ></refinement-list>
              <hr />
              <!-- Features List -->
              <refinement-list
                title="Features"
                :attrs="[{ name: 'feature_list' }]"
              ></refinement-list>
            </b-card>
          </b-col>
          <b-col id="mobileMenu" class="d-block d-lg-none" cols="12">
            <b-row class="my-2 align-items-center">
              <b-col cols="6">
                <ais-menu
                  attribute="new"
                  class="newFilterMenu"
                  :sort-by="['name:desc']"
                  :transform-items="transformMenu"
                >
                  <template v-slot:default="{ items, refine, createURL }">
                    <ul class="condition-new-filters">
                      <li v-for="item in items" :key="item.value">
                        <a
                          :href="createURL(item.value)"
                          :style="{
                            fontWeight: item.isRefined ? 'bold' : '',
                            background: item.isRefined ? '#0072CE' : '',
                            color: item.isRefined ? '#FFFFFF' : '#0072CE',
                            pointerEvents: item.isRefined ? 'none' : 'all',
                          }"
                          @click.prevent="refine(item.value)"
                        >
                          {{ item.label }}
                          <b-badge
                            pill
                            class="count ml-2"
                            :variant="!item.isRefined ? 'primary' : 'light'"
                            >{{ item.count }}
                            <span class="sr-only"
                              >{{ item.label }} vehicle count</span
                            ></b-badge
                          >
                        </a>
                      </li>
                    </ul>
                  </template>
                </ais-menu>
              </b-col>
              <b-col cols="6">
                <ais-sort-by
                  attribute="sortBy"
                  class="clawson-sort-by"
                  :items="[
                    { value: indexName, label: 'Sort by' },
                    {
                      value: indexName + '_price_asc',
                      label: 'Price (Lowest)',
                    },
                    {
                      value: indexName + '_price_desc',
                      label: 'Price (Highest)',
                    },
                    { value: indexName + '_year_desc', label: 'Year (Newest)' },
                    { value: indexName + '_year_asc', label: 'Year (Oldest)' },
                    {
                      value: indexName + '_mileage_asc',
                      label: 'Mileage (Lowest)',
                    },
                    {
                      value: indexName + '_mileage_desc',
                      label: 'Mileage (Highest)',
                    },
                  ]"
                />
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12">
                <ais-search-box
                  placeholder="Search all results"
                  class="mt-3 mb-2 mb-sm-0 my-md-0"
                />
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="4">
                <b-button
                  block
                  class="filterBtn"
                  id="mobileFilterTrigger"
                  v-b-modal.mobileFilterModal
                >
                  <b-icon icon="sliders"></b-icon>
                  Filter
                </b-button>
              </b-col>
              <b-col cols="8">
                <ais-current-refinements :excluded-attributes="['new']">
                  <template v-slot:default="{ items, createURL }">
                    <ul class="ais-currentRefinements-list">
                      <li
                        v-for="item in items"
                        :key="item.attribute"
                        class="ais-currentRefinements-attList"
                      >
                        <button
                          v-for="(refinement, index) in item.refinements"
                          class="ais-currentrefinements-delete ais-currentRefinements-item"
                          :key="
                            [
                              refinement.attribute,
                              refinement.type,
                              refinement.value,
                              refinement.operator,
                            ].join(':')
                          "
                          :href="createURL(refinement)"
                          @click.prevent="item.refine(refinement)"
                        >
                          <span class="ais-currentRefinements-label">
                            {{ transformCurrentRefinementLabel(refinement) }}
                            <span
                              v-if="
                                index == 0 &&
                                index + 1 == item.refinements.length
                              "
                            >
                              {{ getRefinementOperator(refinement) }}
                            </span>
                            <span
                              v-if="index + 1 == item.refinements.length"
                              class="ml-1"
                            >
                              <span v-if="refinement.attribute == 'highway_mpg'"
                                >MPG (HWY)</span
                              >
                              <span v-if="refinement.attribute == 'city_mpg'"
                                >MPG (CITY)</span
                              >
                            </span>
                            <b-icon icon="x"></b-icon>
                          </span>
                        </button>
                      </li>
                    </ul>
                  </template>
                </ais-current-refinements>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="6">
                <ais-stats ref="statsRef">
                  <template v-slot="{ nbHits }">
                    <h3 class="clawson-filter-stats">{{ nbHits }} results</h3>
                  </template>
                </ais-stats>
              </b-col>
              <b-col cols="6">
                <ais-clear-refinements
                  :excluded-attributes="['new']"
                  :class-names="{
                    'ais-ClearRefinements-button':
                      'clearFiltersBtn btn btn-sm btn-link p-0',
                  }"
                >
                  <span slot="resetLabel">Clear Filters</span>
                </ais-clear-refinements>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" lg="10">
            <b-row>
              <b-col cols="12">
                <ais-state-results class="w-100">
                  <template v-slot:default="{ results: { hits, query } }">
                    <ais-infinite-hits
                      v-if="hits.length > 0"
                      ref="hitsRef"
                      :show-previous="true"
                      :escapeHTML="false"
                      :cache="cache"
                    >
                      <template
                        v-slot:default="{ items, isLastPage, refineNext }"
                      >
                        <b-card-group deck>
                          <new-vehicle-card
                            v-for="item in items"
                            :key="item.objectID"
                            :car="item"
                          ></new-vehicle-card>
                        </b-card-group>
                        <div
                          class="sentinal"
                          v-observe-visibility="
                            (isVisible, entry) => 
                              showMore(isVisible, isLastPage, refineNext)
                          "
                        >
                          <div
                            class="text-center mb-3 d-flex justify-content-center"
                            v-if="!isLastPage"
                          >
                            <b-spinner
                              v-for="n in 3"
                              type="grow"
                              :id="`spinner-${n}`"
                              class="align-self-center"
                              label="Loading..."
                              variant="primary"
                              :key="`spinner${n}`"
                            ></b-spinner>
                          </div>
                        </div>
                      </template>
                    </ais-infinite-hits>
                    <div v-else class="mt-5">
                      <h2 class="text-center">No Results Found</h2>
                      <p class="text-center">
                        Please try adjusting your search criteria.
                      </p>
                      <p class="text-center">{{ query }}</p>
                    </div>
                  </template>
                </ais-state-results>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal
      id="mobileFilterModal"
      title="Filters"
      size="xl"
      hide-footer
      centered
      style="padding-left: unset !important; margin-top: 4rem"
      :static="true"
    >
      <!-- certification list -->
      <refinement-list
        title="Certification"
        :attrs="[{ name: 'certification' }]"
      ></refinement-list>
      <hr />
      <!-- make, model and trim lists -->
      <refinement-list
        title="Make, Model & Trim"
        :attrs="[
          {
            name: 'make',
            header: true,
          },
          {
            name: 'model',
            header: true,
          },
          {
            name: 'trim',
            header: true,
          },
        ]"
      ></refinement-list>
      <hr />
      <!-- Price Slider -->
      <vue-slider-comp
        title="Price"
        :attrs="[{ name: 'price' }]"
      ></vue-slider-comp>
      <hr />
      <!-- Year and Mileage Sliders -->
      <vue-slider-comp
        title="Year & Mileage"
        :attrs="[
          {
            name: 'year',
            header: true,
          },
          {
            name: 'mileage',
            header: true,
          },
        ]"
      ></vue-slider-comp>
      <hr />
      <!-- Body Type list -->
      <refinement-list
        title="Body Type"
        :attrs="[{ name: 'body' }]"
      ></refinement-list>
      <hr />
      <!-- MPG Sliders -->
      <vue-slider-comp
        title="Miles Per Gallon"
        :attrs="[
          {
            name: 'highway_mpg',
            header: true,
          },
          {
            name: 'city_mpg',
            header: true,
          },
        ]"
      ></vue-slider-comp>
      <hr />
      <!-- Drivetrain list -->
      <refinement-list
        title="Drive Train"
        :attrs="[{ name: 'drivetrain_description' }]"
      ></refinement-list>
      <hr />
      <!-- Cylinders & Door Count Lists -->
      <refinement-list
        title="Cylinders & Door Count"
        :attrs="[
          {
            name: 'cylinders',
            header: true,
          },
          {
            name: 'door_count',
            header: true,
          },
        ]"
      ></refinement-list>
      <hr />
      <!-- Interior Material List -->
      <refinement-list
        title="Interior Material"
        :attrs="[{ name: 'interior_material' }]"
      ></refinement-list>
      <hr />
      <!-- Color List -->
      <refinement-list
        title="Color"
        :attrs="[{ name: 'color', colorItem: true }]"
      ></refinement-list>
      <hr />
      <!-- Fuel Type & Engine Lists -->
      <refinement-list
        title="Fuel Type & Engine"
        :attrs="[
          {
            name: 'fuel',
            header: true,
          },
          {
            name: 'engine',
            header: true,
          },
        ]"
      ></refinement-list>
      <hr />
      <!-- Transmission List -->
      <refinement-list
        title="Transmission"
        :attrs="[{ name: 'transmission' }]"
      ></refinement-list>
      <hr />
      <!-- Features List -->
      <refinement-list
        title="Features"
        :attrs="[{ name: 'feature_list' }]"
      ></refinement-list>
    </b-modal>
    <b-modal
      id="details-disclaimer-modal"
      title="Details Disclaimer"
      size="lg"
      hide-footer
      centered
      style="padding-left: unset !important; margin-top: 4rem"
      :static="true"
    >
      <p>
        All vehicles are one of each. All Pre-Owned or Certified vehicles are
        Used. Tax, Title, and Tags are not included in vehicle prices shown and
        must be paid by the purchaser. All offers expire on close of business
        the day subject content is removed from website, and all financing is
        subject to credit approval. All transactions are negotiable including
        price, trade allowance, interest rate (of which the dealer may retain a
        portion), term, and documentary service fee. $85 dealer documentation
        charge is non-negotiable. Any agreement is subject to execution of
        contract documents. While great effort is made to ensure the accuracy of
        the information on this site, errors do occur so please verify
        information with a customer service rep. This is easily done by calling
        us at 559-435-5000 or by visiting us at the dealership.
      </p>
      <p>
        New and used prices do not include government fees, and taxes, any
        finance charges, any dealer document processing charge, any electronic
        filing charge, and any emission testing charge. Notice, all of our
        vehicles have an anti-theft system installed. The advertised price
        excludes the purchase of this anti-theft system.
      </p>
      <p>
        **With approved credit. Terms may vary. Monthly payments are only
        estimates derived from the vehicle price with a 72-month term, 4.9%
        interest, and 20% down payment.
      </p>
      <p>
        Some of our used vehicles may be subject to unrepaired safety recalls.
        Check for a vehicle’s unrepaired recalls by VIN at
        <a href="https://vinrcl.safecar.gov/vin/">Check for Recalls Here</a>
      </p>
      <p>
        *MPG on non-hybrid vehicles based on EPA mileage ratings. Use for
        comparison purposes only. Your mileage will vary depending on how you
        drive and maintain your vehicle, driving conditions, and other factors.
      </p>
      <p>
        *MPG for hybrid vehicles based on EPA mileage ratings. Use for
        comparison purposes only. Your mileage will vary depending on how you
        drive and maintain your vehicle, driving conditions, battery-pack
        age/condition (hybrid trims), and other factors.
      </p>
      <p>
        While every effort has been made to ensure display of accurate data, the
        vehicle listings within this web site may not reflect all accurate
        vehicle items including photos, information appearing herein are
        presented and declared to the user on as “as-is” basis without any
        warranties of any kind, either expressed or implied, including but not
        limited to the implied warranties of merchantability. All inventory
        listed is subject to prior sale. The vehicle photo displayed may be an
        example only. Please see the dealer for details. Some vehicles shown
        with optional equipment. See the actual vehicle for complete accuracy of
        features, options & pricing. Because of the numerous possible
        combinations of vehicle models, styles, colors and options, the vehicle
        pictures on this site may not match your vehicle exactly; however, it
        will match as closely as possible. Some vehicle images shown are stock
        photos and may not reflect your exact choice of vehicle, color, trim and
        specification. Not responsible for pricing or typographical errors.
        Please verify individual inventory price(s) with the dealer.
      </p>
      <p>
        All information pertaining to any vehicle should be verified through the
        dealer.
      </p>
      <p>
        *Notice regarding Security System: All of our vehicles are equipped with
        an anti-theft device to prevent theft from our inventory. The advertised
        price for all of our vehicles does not include the price of the
        anti-theft device. This device can be purchased for an additional cost,
        or may be removed at the customer’s option.
      </p>
      <p>
        Any image of a stock photo may not reflect the model year or color of,
        and/or equipment offered on, the advertised vehicle. The image is shown
        for illustration purposes only.
      </p>
      <p>
        Proposition 65 Warning: Operating, servicing, and maintaining a
        passenger vehicle or off-road vehicle can expose you to chemicals
        including engine exhaust, carbon monoxide, phthalates, and lead, which
        are known to the State of California to cause cancer and birth defects
        or other reproductive harm. To minimize exposure, avoid breathing
        exhaust, do not idle the engine except as necessary, service your
        vehicle in a well-ventilated area, and wear gloves or wash your hands
        frequently when servicing your vehicle. For more information go to
        <a href="https://www.p65warnings.ca.gov/products/passenger-vehicle/"
          >p65Warnings</a
        >
      </p>
      <p>ADM = Added Dealer Markup</p>
    </b-modal>
  </ais-instant-search>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import RefinementList from "./RefinementList.vue";
import VueSliderComp from "./VueSliderComp.vue";
import { routingLogic } from "./routing";
import { ALGOLIA_APP_ID, ALGOLIA_INDEX, ALGOLIA_API_KEY } from "config";
import { createInfiniteHitsSessionStorageCache } from "instantsearch.js/es/lib/infiniteHitsCache";

export default {
  name: "newSearchComponent",
  data: () => ({
    searchClient: algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY),
    indexName: ALGOLIA_INDEX,
    routing: routingLogic,
  }),
  computed: {
    cache() {
      return createInfiniteHitsSessionStorageCache()
    },
  },
  methods: {
    showMore: function (isVisible, isLastPage, refineNext) {
      if (isVisible && !isLastPage) {
        refineNext();
      }
    },

    transformMenu: (items) => {
      const mappedItem = (item) => ({
        count: item.count,
        data: item.data,
        exhaustive: item.exhaustive,
        isRefined: item.isRefined,
        label: item.label === "true" ? "New" : "Used",
        value: item.value,
      });
      if (items.length > 1) {
        return items.map(mappedItem);
      } else {
        items.push({
          count: 0,
          data: null,
          exhaustive: true,
          isRefined: false,
          label: items[0].label === "true" ? "false" : "true",
          value: items[0].value === "true" ? "false" : "true",
        });
        return items
          .map(mappedItem)
          .sort((a, b) => (a.label === "New" ? -1 : 1));
      }
    },

    transformCurrentRefinementLabel: (refinement) => {
      let newLabel;
      const { attribute: category, value } = refinement;
      switch (category) {
        case "new":
          newLabel = value ? "New" : "Pre-Owned";
          break;
        case "certified":
          newLabel = "CPO";
          break;
        case "price":
          newLabel = `$ ${value.toLocaleString()}`;
          break;
        case "door_count":
          newLabel = `${value} doors`;
          break;
        case "cylinders":
          newLabel = `${value} cylinders`;
          break;
        default:
          newLabel = value;
      }

      return newLabel;
    },

    getRefinementOperator: (refinement) => {
      let newOperator;
      const { operator, attribute } = refinement;
      switch (attribute) {
        case "year":
          newOperator =
            operator === "<="
              ? "or older"
              : operator === ">="
              ? "or newer"
              : "";
          break;
        case "price":
        case "mileage":
        case "highway_mpg":
        case "city_mpg":
          newOperator =
            operator === "<=" ? "or less" : operator === ">=" ? "or more" : "";
      }

      return newOperator;
    },

    // transformHits : function( items ) {
    // 	return items.reduce( ( rows, item, i ) => {
    // 		if ( i % this.getNumberPerRow() === 0 ) rows.push( [] );
    // 		rows[rows.length - 1].push( item );
    // 		return rows;
    // 	}, []);
    // },

    // getNumberPerRow : function() {
    // 	return window.innerWidth < 992 ? 1 : window.innerWidth < 1345 ? 2 : 3;
    // }
  },
  mounted() {
    $(".dropdown-menu").click((e) => e.stopPropagation());
  },
  components: {
    RefinementList,
    VueSliderComp,
  },
};
</script>
